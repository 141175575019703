<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="姓名">
            <el-input v-model="searchForm.name" placeholder="请输入 姓名"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="状态">
            <el-select v-model="searchForm.state" placeholder="请选择 状态">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button @click="dialogVisible = true" class="searchBtn">新增</el-button>
        <el-button @click="plqy" class="searchBtn">批量启用</el-button>
        <el-button @click="pljy" class="searchBtn">批量禁用</el-button>
        <el-button @click="plsc" class="searchBtn">批量删除</el-button>
      </template>
    </Searchbar>

    <basicTable
        @selectChange="selectChange"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog title="新增" v-model="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="demoform" label-position="right" label-width="120px" :model="form">
        <el-form-item label="账号" :rules="[{ required: true, message: '账号' }]" prop="userNo">
          <el-input v-model="form.userNo" />
        </el-form-item>
        <el-form-item label="姓名" :rules="[{ required: true, message: '姓名' }]" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="账号状态" :rules="[{ required: true, message: '账号状态' }]" prop="state">
          <el-select v-model="form.state" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="过期时间" :rules="[{ required: true, message: '过期时间' }]" prop="expiryDate">
          <el-date-picker v-model="form.expiryDate" value-format="YYYY-MM-DD" type="date" placeholder="过期时间"/>
        </el-form-item>
        <el-form-item label="描述" prop="text">
          <el-input v-model="form.text" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="trans" >提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { dataList, dataSave, dataMove, changeState } from "@/api/fireproof/account";
import Searchbar from "@/components/Searchbar";
import {onMounted, reactive, ref, toRefs} from "vue";
import {ElMessage} from "element-plus";
export default {
  components:{
    Searchbar
  },
  setup(){
    let demoform = ref(null);
    let dialogVisible = ref(false);
    let state = reactive({
      ids:[],
      options:[
        {
          value: '1',
          label: '--启用--',
        },
        {
          value: '2',
          label: '--禁用--',
        },
      ],
      searchForm: {},
      form: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          prop: "userNo",
          label: "用户账号",
          render: (row) => <div>{row.userNo}</div>
        },
        {
          prop: "name",
          label: "姓名",
          render: (row) => <div>{row.name}</div>
        },
        {
          prop: "addTime",
          label: "录入时间",
          render: (row) => <div>{row.addTime}</div>
        },
        {
          prop: "expiryDate",
          label: "到期时间",
          render: (row) => <div>{row.expiryDate}</div>
        },
        {
          prop: "state",
          label: "状态",
          render: (row) =><el-switch onChange={()=>{change(row)}} value={row.state == 1 ? true : false } size="large" active-text="启用" inactive-text="禁用"/>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="View" onClick={() => dataDel(row)}>删除</el-button>
        }
      ]
    })

    onMounted(()=>{
      onLoad();
    })

    function trans(){
      demoform.value.validate((valid) => {
        if (valid) {
          dataSave({ userNo:state.form.userNo, name:state.form.name, state:state.form.state, expiryDate:state.form.expiryDate }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              state.page.current = 1;
              state.searchForm = {};
              dialogVisible.value = false;
              onLoad();
            }
          })
        } else {
          return false
        }
      })
    }

    function onSearch() {
      state.page.current = 1;
      onLoad();
    }

    function resetForm() {
      state.page.current = 1;
      state.searchForm = {};
      onLoad();
    }

    async function change(row){
      let res = await changeState({userNos:row.userNo ,state:row.state == 1 ? 2 : 1});
      if(res.data.success){
        ElMessage({message: res.data.code.message, type: 'success'});
        onLoad();
      }
    }

    function dataDel(row){
      dataMove({ userNos: row.userNo }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          onLoad();
        }
      })
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad();
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad();
    }

    function selectChange(val){
      state.ids = val.map(item => item.userNo);
    }

    async function pljy(){
      if(state.ids.length < 1) return
      let res = await changeState({userNos: state.ids.join(',') ,state: 2});
      if(res.data.success){
        ElMessage({message: res.data.code.message, type: 'success'});
        onLoad();
      }
    }

    async function plqy(){
      if(state.ids.length < 1) return
      let res = await changeState({userNos: state.ids.join(',') ,state: 1});
      if(res.data.success){
        ElMessage({message: res.data.code.message, type: 'success'});
        onLoad();
      }
    }

    async function plsc(){
      if(state.ids.length < 1) return;
      let res = await dataMove({userNos: state.ids.join(',')});
      if(res.data.success){
        ElMessage({message: res.data.code.message, type: 'success'});
        state.page.current = 1;
        onLoad();
      }
    }

    function onLoad() {
      dataList({ limit: state.page.limit, page: state.page.current, ...state.searchForm }).then(res => {
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      });
    }

    return{
      plsc,
      plqy,
      pljy,
      selectChange,
      onSearch,
      resetForm,
      handleCurrentChange,
      handleSizeChange,
      demoform,
      trans,
      dialogVisible,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-switch{
  padding: 0;
  display: flex;
  justify-content: center;
}
</style>