import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/protect/userListData",
        method: "post",
        params: params,
    });
};


export const dataSave = (params) => {
    return axios({
        url: "/api/protect/addProtectUser",
        method: "post",
        params: params,
    });
};

export const dataMove = (params) => {
    return axios({
        url: "/api/protect/deleteProtectUser",
        method: "post",
        params: params,
    });
};


export const changeState = (params) => {
    return axios({
        url: "/api/protect/updateUserState",
        method: "post",
        params: params,
    });
};